import React, { PropsWithChildren, useEffect } from 'react';
import { cn } from '@/Utils';
import MainNav from '@/Layouts/Partials/MainNav';
import { resolvePageComponent } from 'laravel-vite-plugin/inertia-helpers';
import EnableNotificationsPrompt from '@/Components/EnableNotificationsPrompt';
import useTypedPage from '@/Hooks/useTypedPage';
import { Toaster } from '@/Components/ui/toaster';
import { useToast } from '@/Components/ui/use-toast';
import { ModalProvider } from '@/Components/momentum-modal/ModalContext';

const resolve = (name: string) => resolvePageComponent(
  `../Pages/${name}.tsx`,
  import.meta.glob('../Pages/**/*.tsx'),
);

interface Props {
  container?: boolean;
  safeZone?: boolean;
  hideNav?: boolean;
  className?: string;
}

export default function AppLayout({ container = true, safeZone = true, hideNav = false, children, className }: PropsWithChildren<Props>) {
  const { props } = useTypedPage();
  const { toast } = useToast();

  useEffect(() => {
    if (props.flash && props.flash.message !== null) {
      toast({ description: props.flash.message });
    }
  }, [props?.flash]);

  return (
    <ModalProvider resolve={resolve}>
      <div className={cn('relative w-full min-h-svh overflow-x-hidden')}>
        {/* <!-- Nav --> */}
        {(props.auth?.user && !props.hideNav && !hideNav) && <MainNav />}

        {/* <!-- Page Content --> */}
        <main className={cn(
          'flex flex-col min-h-svh',
          container && 'container',
          safeZone && 'pt-5 pb-[110px] sm:min-h-[calc(100svh-65px)] sm:pb-5',
          className
        )}>
          {children}
        </main>

        <Toaster />

        {props.promptToEnableNotifications && <EnableNotificationsPrompt />}
      </div>
    </ModalProvider>
  );
}

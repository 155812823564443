import React, {PropsWithChildren} from 'react';
import {Button} from "@/Components/ui/button";
import {Link} from "@inertiajs/react";
import useRoute from "@/Hooks/useRoute";
import {UserCogIcon} from "lucide-react";
import useTypedPage from "@/Hooks/useTypedPage";
import AppLayout from "@/Layouts/AppLayout";

export default function PetOnboardingLayout({children}: PropsWithChildren) {
  const {backUrl} = useTypedPage().props;
  const route = useRoute();

  return (
    <AppLayout container={true} hideNav={true} className="max-w-md mx-auto flex flex-col justify-start w-full gap-10">
      <div className="w-full mx-auto flex items-center justify-between gap-5 -mb-5">
        <div>
          {backUrl && (
            <Button
              variant="nav"
              size="sm"
              chunky="off"
              asChild
            >
              <Link href={backUrl}>
                &lt; Back
              </Link>
            </Button>
          )}
        </div>

        <Link href={route('profile.show')}>
          <UserCogIcon className="w-6 h-6 text-muted-foreground hover:text-muted-foreground/50"/>
        </Link>
      </div>

      {children}
    </AppLayout>
  );
}
